/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import VerticalMenu from '../components/blogComponents/verticalMenu'

const Template = ({ data, pathContext }) => {
    const title = data.markdownRemark.frontmatter.title
    const date = data.markdownRemark.frontmatter.date
    const html = data.markdownRemark.html
    //const { next, prev } = pathContext

    return (
        <Layout>
            <Seo title="Blog" description="Blog page BricoBrowser" />
            <div className="back">
            </div>
            <section className="comment">
                <div className="leftPart">
                    <div className="firstComment">
                        <h3>{title}</h3>
                        <p className="little">{date}</p>
                        <div dangerouslySetInnerHTML={{__html: html}}/>
                    </div>
                    
                </div>
                <div className="vertical"> 
                </div>
                <VerticalMenu/>
            </section>
        </Layout>
    )
}

export const postQuery = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM, DD, YYYY")
        path
        tags
        excerpt
      }
    }
  }
`

export default Template